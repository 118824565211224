import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Catalyst } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageEntity, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('catalyst-aside-layout');

// -------- Types -------->

export type CatalystAsideLayoutProps = Classable & HasChildren & { 
  entity?: Catalyst;
};

export const CatalystAsideLayoutDefaultProps: Omit<CatalystAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ImageStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)};
    max-width: calc(100% - ${theme.UNIT(10)});
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Image: styled(ImageEntity)`${ImageStyles}`
};

export const CatalystAsideLayout: Shapeable.FC<CatalystAsideLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
   <My.Container className={cls.name(className)}>
    <My.Image entity={entity.openGraph} />
   </My.Container>
  )
};

CatalystAsideLayout.defaultProps = CatalystAsideLayoutDefaultProps;
CatalystAsideLayout.cls = cls;