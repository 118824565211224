import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Catalyst } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, ContentTitle, useEntity, useLang } from '@shapeable/ui';
import { ExplorerView } from '../elements/explorer-view';
import { EntityEmbedReveal } from './entity-embed-reveal';
import { classNames } from '@shapeable/utils';
const cls = classNames('catalyst-main-layout');

// -------- Types -------->

export type CatalystMainLayoutProps = Classable & HasChildren & { 
  entity?: Catalyst;
};

export const CatalystMainLayoutDefaultProps: Omit<CatalystMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: ${theme.COLOR('heading')};
    font-size: 1em;
    margin-bottom: 0.5em;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const ExampleStyles = breakpoints({
  base: css`
    
  `,
});

const InnovationsStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
    ContentTitle: styled(ContentTitle)`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Example: styled(ContentNode)`${ExampleStyles}`,
      Innovations: styled(EntityEmbedReveal)`${InnovationsStyles}`,
};

export const CatalystMainLayout: Shapeable.FC<CatalystMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, innovations = [], exampleModel } = entity;

  const hasInnovations = !!innovations;

  const t = useLang();
  
  return (
   <My.Container className={cls.name(className)}>
    <ExplorerView>
      <My.Section>
        <My.Description entity={description as TContentNode} />
      </My.Section>
      <My.Section>
        <My.ContentTitle>{t('Example Model')}</My.ContentTitle>
        <My.Example entity={exampleModel as TContentNode} />
      </My.Section>
        {
          hasInnovations && (
            <My.Section>
              <My.ContentTitle>{t('Innovations')}</My.ContentTitle>
              {
                innovations.map(innovation => (
                  <My.Innovations key={innovation.id} entity={innovation} />
                ))
              }
            </My.Section>
          )}
      {children}
    </ExplorerView>
   </My.Container>
  )
};

CatalystMainLayout.defaultProps = CatalystMainLayoutDefaultProps;
CatalystMainLayout.cls = cls;